import React from "react";
import Layout from "../components/layout/Layout";
import NavBar from "../components/navigations/NavBar";
import FootBar from "../components/navigations/FootBar";
import MiddleOne from "../components/navigations/MiddleOne";
import NosTechnologies from "../components/layout/NosTechnologies";
import { useTranslation } from "react-i18next";
import "../translations/i18n";
import ReactTooltip from 'react-tooltip';
import {getPartnersBis} from "../utils/Common";
import {StaticImage} from "gatsby-plugin-image";

const SeproTechnology = () => {
    const {t} = useTranslation();
    const partners = getPartnersBis()
    return(
    <Layout pageTitle={t("sepro_technology")}>
        <div className="min-h-full bg-gray-200" >
            <NavBar active={'technology'} page="technology"/>
            <div className="px-4 py-8 md:py-10 md:p-10 bg-sepro-technology-1">
                <div className="relative flex flex-col items-start max-w-7xl px-6 py-8 mx-auto text-left sm:my-10 lg:px-0 mb-8">
                    <h1 className="max-w-2xl text-3xl font-extrabold tracking-tight text-white lg:text-6xl">{t("sepro")}</h1>
                    <h1 className="max-w-2xl text-3xl font-extrabold tracking-tight text-white lg:text-6xl mb-8">{t("technology")}</h1>
                    <p className="max-w-sm text-sm md:text-base text-gray-400 text-justify mb-8">{t("sepro_technology_msg_1")}</p>
                </div>
            </div>
            <div className="mb-36">
                <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-none lg:px-8">
                    {/* Main 3 column grid */}
                    <NosTechnologies/>
                </div>
            </div>
            <div className="-mt-24">
            <div className="-mb-24">
                <div className="max-w-2xl mx-auto lg:max-w-none p-10 z-20">
                    <div className="bg-white grid grid-cols-1">
                        <div className="bg-white grid grid-cols-1 gap-1 items-start sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-7 lg:gap-2">
                            <div className="grid grid-cols-1 gap-2 mb-5">
                                {/* CISCO */}
                                <section aria-labelledby="cisco-title">
                                    <div className="bg-white overflow-hidden">
                                        <div className="p-6">
                                            <div aria-hidden="true">
                                                <StaticImage
                                                    src={'../../static/partenaires/cisco.jpg'}
                                                    alt={'CISCO'}
                                                    className="object-cover object-center w-full h-full"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div className="grid grid-cols-1 gap-2 mb-5">
                                {/* microsoft */}
                                <section aria-labelledby="cisco-title">
                                    <div className="bg-white overflow-hidden">
                                        <div className="p-6">
                                            <div aria-hidden="true">
                                                <StaticImage
                                                    src={'../../static/partenaires/microsoft.jpg'}
                                                    alt={'microsoft'}
                                                    className="object-cover object-center w-full h-full"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div className="grid grid-cols-1 gap-2 mb-5">
                                {/* CISCO */}
                                <section aria-labelledby="cisco-title">
                                    <div className="bg-white overflow-hidden">
                                        <div className="p-6">
                                            <div aria-hidden="true">
                                                <StaticImage
                                                    src={'../../static/partenaires/dlink.jpg'}
                                                    alt={'dlink'}
                                                    className="object-cover object-center w-full h-full"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div className="grid grid-cols-1 gap-2 mb-5">
                                {/* CISCO */}
                                <section aria-labelledby="cisco-title">
                                    <div className="bg-white overflow-hidden">
                                        <div className="p-6">
                                            <div aria-hidden="true">
                                                <StaticImage
                                                    src={'../../static/partenaires/dell.jpg'}
                                                    alt={'dell'}
                                                    className="object-cover object-center w-full h-full"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div className="grid grid-cols-1 gap-2 mb-5">
                                {/* CISCO */}
                                <section aria-labelledby="cisco-title">
                                    <div className="bg-white overflow-hidden">
                                        <div className="p-6">
                                            <div aria-hidden="true">
                                                <StaticImage
                                                    src={'../../static/partenaires/hp.jpg'}
                                                    alt={'HP'}
                                                    className="object-cover object-center w-full h-full"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div className="grid grid-cols-1 gap-2 mb-5">
                                {/* lenovo */}
                                <section aria-labelledby="cisco-title">
                                    <div className="bg-white overflow-hidden">
                                        <div className="p-6">
                                            <div aria-hidden="true">
                                                <StaticImage
                                                    src={'../../static/partenaires/lenovo.jpg'}
                                                    alt={'lenovo'}
                                                    className="object-cover object-center w-full h-full"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div className="grid grid-cols-1 gap-2 mb-5">
                                {/* epson */}
                                <section aria-labelledby="cisco-title">
                                    <div className="bg-white overflow-hidden">
                                        <div className="p-6">
                                            <div aria-hidden="true">
                                                <StaticImage
                                                    src={'../../static/partenaires/epson.jpg'}
                                                    alt={'epson'}
                                                    className="object-cover object-center w-full h-full"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                    </div>
                    </div>
                </div>
            </div>
            </div>
            <div className="empty-space">&nbsp;</div>
            <MiddleOne/>

            <FootBar active={'technology'}/>
        </div>
        <ReactTooltip />
    </Layout>
)
}

export default SeproTechnology;
