import React from "react";
import {useTranslation} from "react-i18next";
import "../../translations/i18n";
import {StaticImage} from "gatsby-plugin-image";

export default function NosTechnologies() {
    const {t} = useTranslation();
    return (
        <div className="-mt-24">
            {/* telephonie */}
            <div className="bg-white max-w-2xl mx-auto lg:max-w-none my-16">
                <div className="space-y-0">
                    <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                        <div
                            className="lg:col-start-1 xl:col-start-1 mt-6 lg:mt-0 lg:row-start-1 lg:col-span-6 xl:col-span-6">
                            <div className="aspect-w-5 aspect-h-2 bg-gray-100 overflow-hidden">
                                <div aria-hidden="true">
                                    <StaticImage
                                        src={"../../../static/technologies/telephonie.jpg"}
                                        alt={t('sepro_telephonie')}
                                        className="object-cover object-center w-full h-full"
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className="lg:col-start-7 flex-auto lg:row-start-1 lg:col-span-6 xl:col-span-6">
                            <div key=""
                                 className="flex flex-col-reverse lg:grid lg:items-center">
                                {/*  <div className="lg:col-start-1 xl:col-start-1 mt-6 lg:mt-0 lg:row-start-1 lg:col-span-3 xl:col-span-4">
                                                <div className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                                                    <div aria-hidden="true">
                                                        <img
                                                            src={"technologies/icons/"+technology.icon}
                                                            alt={t(technology.title)}
                                                            className="object-cover object-center w-24 h-24 mx-auto"
                                                        />
                                                    </div>
                                                </div>
                                        </div>*/}
                                <div
                                    className="lg:col-start-1 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 px-4">
                                    <h2 className="text-lg font-bold text-gray-900 mb-4">{t('sepro_telephonie')}</h2>
                                    <p className="mt-2 text-sm md:text-base text-gray-500">{t('sepro_techno_msg_telephonie')}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* reseau_systeme entreprise */}
            <div className="bg-white max-w-2xl mx-auto lg:max-w-none my-4">
                <div className="space-y-0">
                    <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                        <div
                            className="lg:col-start-1 xl:col-start-1 mt-6 lg:mt-0 lg:row-start-1 lg:col-span-6 xl:col-span-6">
                            <div key=""
                                 className="flex flex-col-reverse lg:grid lg:items-center">
                                {/*<div className="lg:col-start-1 xl:col-start-1 mt-6 lg:mt-0 lg:row-start-1 lg:col-span-3 xl:col-span-4">
                                                <div className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                                                    <div aria-hidden="true">
                                                        <img
                                                            src={"technologies/icons/"+technology.icon}
                                                            alt={t(technology.title)}
                                                            className="object-cover object-center w-24 h-24 mx-auto"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            */}
                                <div
                                    className="lg:col-start-1 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 px-4">
                                    <h2 className="text-lg font-bold text-gray-900 mb-4">{t('sepro_reseau_systeme')}</h2>
                                    <p className="mt-2 text-sm md:text-base text-gray-500">{t('sepro_techno_msg_reseau_systeme')}</p>
                                    {/*<ul className="pl-10 mt-1 text-sm md:text-base text-gray-900 list-disc text-left">
                                        <li className="mt-1 list-item">{t(list)}</li>
                                    </ul>*/}
                                </div>
                            </div>
                        </div>
                        <div
                            className="lg:col-start-7 flex-auto lg:row-start-1 lg:col-span-6 xl:col-span-6">
                            <div className="aspect-w-5 aspect-h-2 bg-gray-100 overflow-hidden">
                                <div aria-hidden="true">
                                    <StaticImage
                                        src={"../../../static/technologies/reseau-systeme-dentreprise.jpg"}
                                        alt={t('sepro_reseau_systeme')}
                                        className="object-cover object-center w-full h-full"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* électricité */}
            <div className="bg-white max-w-2xl mx-auto lg:max-w-none my-16">
                <div className="space-y-0">
                    <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                        <div
                            className="lg:col-start-1 xl:col-start-1 mt-6 lg:mt-0 lg:row-start-1 lg:col-span-6 xl:col-span-6">
                            <div className="aspect-w-5 aspect-h-2 bg-gray-100 overflow-hidden">
                                <div aria-hidden="true">
                                    <StaticImage
                                        src={"../../../static/technologies/electricite.jpg"}
                                        alt={t('sepro_electricity')}
                                        className="object-cover object-center w-full h-full"
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className="lg:col-start-7 flex-auto lg:row-start-1 lg:col-span-6 xl:col-span-6">
                            <div key=""
                                 className="flex flex-col-reverse lg:grid lg:items-center">
                                {/*  <div className="lg:col-start-1 xl:col-start-1 mt-6 lg:mt-0 lg:row-start-1 lg:col-span-3 xl:col-span-4">
                                                <div className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                                                    <div aria-hidden="true">
                                                        <img
                                                            src={"technologies/icons/"+technology.icon}
                                                            alt={t(technology.title)}
                                                            className="object-cover object-center w-24 h-24 mx-auto"
                                                        />
                                                    </div>
                                                </div>
                                        </div>*/}
                                <div
                                    className="lg:col-start-1 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 px-4">
                                    <h2 className="text-lg font-bold text-gray-900 mb-4">{t('sepro_electricity')}</h2>
                                    <p className="mt-2 text-sm md:text-base text-gray-500">{t('sepro_techno_msg_electricity')}</p>
                                    <ul className="pl-10 mt-1 text-sm md:text-base text-gray-900 list-disc text-left">
                                        <li className="mt-1 list-item">{t('sepro_electricity_high')}</li>
                                        <li className="mt-1 list-item">{t('sepro_electricity_low')}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* wifi d'entreprise */}
            <div className="bg-white max-w-2xl mx-auto lg:max-w-none my-4">
                <div className="space-y-0">
                    <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                        <div
                            className="lg:col-start-1 xl:col-start-1 mt-6 lg:mt-0 lg:row-start-1 lg:col-span-6 xl:col-span-6">
                            <div key=""
                                 className="flex flex-col-reverse lg:grid lg:items-center">
                                {/*<div className="lg:col-start-1 xl:col-start-1 mt-6 lg:mt-0 lg:row-start-1 lg:col-span-3 xl:col-span-4">
                                                <div className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                                                    <div aria-hidden="true">
                                                        <img
                                                            src={"technologies/icons/"+technology.icon}
                                                            alt={t(technology.title)}
                                                            className="object-cover object-center w-24 h-24 mx-auto"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            */}
                                <div
                                    className="lg:col-start-1 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 px-4">
                                    <h2 className="text-lg font-bold text-gray-900 mb-4">{t('sepro_wifi')}</h2>
                                    <p className="mt-2 text-sm md:text-base text-gray-500">{t('sepro_techno_msg_wifi_1')}</p>
                                    <p className="mt-2 text-sm md:text-base text-gray-500">{t('sepro_techno_msg_wifi_2')}</p>
                                    <ul className="pl-10 mt-1 text-sm md:text-base text-gray-900 list-disc text-left">
                                        <li className="mt-1 list-item">{t('sepro_techno_msg_wifi_3')}</li>
                                        <li className="mt-1 list-item">{t('sepro_techno_msg_wifi_4')}</li>
                                        <li className="mt-1 list-item">{t('sepro_techno_msg_wifi_5')}</li>
                                        <li className="mt-1 list-item">{t('sepro_techno_msg_wifi_6')}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div
                            className="lg:col-start-7 flex-auto lg:row-start-1 lg:col-span-6 xl:col-span-6">
                            <div className="aspect-w-5 aspect-h-2 bg-gray-100 overflow-hidden">
                                <div aria-hidden="true">
                                    <StaticImage
                                        src={"../../../static/technologies/wifi.jpg"}
                                        alt={t('sepro_reseau_systeme')}
                                        className="object-cover object-center w-full h-full"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* infogérance */}
            <div className="bg-white max-w-2xl mx-auto lg:max-w-none my-16">
                <div className="space-y-0">
                    <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                        <div
                            className="lg:col-start-1 xl:col-start-1 mt-6 lg:mt-0 lg:row-start-1 lg:col-span-6 xl:col-span-6">
                            <div className="aspect-w-5 aspect-h-2 bg-gray-100 overflow-hidden">
                                <div aria-hidden="true">
                                    <StaticImage
                                        src={"../../../static/technologies/infogerence.jpg"}
                                        alt={t('sepro_outsourcing')}
                                        className="object-cover object-center w-full h-full"
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className="lg:col-start-7 flex-auto lg:row-start-1 lg:col-span-6 xl:col-span-6">
                            <div key=""
                                 className="flex flex-col-reverse lg:grid lg:items-center">
                                {/*  <div className="lg:col-start-1 xl:col-start-1 mt-6 lg:mt-0 lg:row-start-1 lg:col-span-3 xl:col-span-4">
                                                <div className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                                                    <div aria-hidden="true">
                                                        <img
                                                            src={"technologies/icons/"+technology.icon}
                                                            alt={t(technology.title)}
                                                            className="object-cover object-center w-24 h-24 mx-auto"
                                                        />
                                                    </div>
                                                </div>
                                        </div>*/}
                                <div
                                    className="lg:col-start-1 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 px-4">
                                    <h2 className="text-lg font-bold text-gray-900 mb-4">{t('sepro_outsourcing')}</h2>
                                    <p className="mt-2 text-sm md:text-base text-gray-500">{t('sepro_techno_msg_outsourcing_1')}</p>
                                    <ul className="pl-10 mt-1 text-sm md:text-base text-gray-900 list-disc text-left">
                                        <li className="mt-1 list-item">{t('sepro_techno_msg_outsourcing_2')}</li>
                                        <li className="mt-1 list-item">{t('sepro_techno_msg_outsourcing_3')}</li>
                                        <li className="mt-1 list-item">{t('sepro_techno_msg_outsourcing_4')}</li>
                                        <li className="mt-1 list-item">{t('sepro_techno_msg_outsourcing_5')}</li>
                                        <li className="mt-1 list-item">{t('sepro_techno_msg_outsourcing_6')}</li>
                                        <li className="mt-1 list-item">{t('sepro_techno_msg_outsourcing_7')}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="bg-white max-w-2xl mx-auto lg:max-w-none my-4">
                <div className="space-y-0">
                    <div key="" className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:items-center">
                        <div
                            className="lg:col-start-1 xl:col-start-1 mt-6 lg:mt-0 lg:row-start-1 lg:col-span-6 xl:col-span-6">
                            <div key=""
                                 className="flex flex-col-reverse lg:grid lg:items-center">
                                {/*<div className="lg:col-start-1 xl:col-start-1 mt-6 lg:mt-0 lg:row-start-1 lg:col-span-3 xl:col-span-4">
                                                <div className="aspect-w-5 items-center col-auto text-center aspect-h-2 overflow-hidden">
                                                    <div aria-hidden="true">
                                                        <img
                                                            src={"technologies/icons/"+technology.icon}
                                                            alt={t(technology.title)}
                                                            className="object-cover object-center w-24 h-24 mx-auto"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            */}
                                <div
                                    className="lg:col-start-1 flex-auto lg:row-start-1 lg:col-span-9 xl:col-span-8 px-4">
                                    <h2 className="text-lg font-bold text-gray-900 mb-4">{t('sepro_cybersecurity')}</h2>
                                    <p className="mt-2 text-sm md:text-base text-gray-500">{t('sepro_techno_msg_cybersecurity_1')}</p>
                                    <p className="mt-2 text-sm md:text-base text-gray-500">{t('sepro_techno_msg_cybersecurity_2')}</p>
                                    <ul className="pl-10 mt-1 text-sm md:text-base text-gray-900 list-disc text-left">
                                        <li className="mt-1 list-item">{t('sepro_techno_msg_cybersecurity_3')}</li>
                                        <li className="mt-1 list-item">{t('sepro_techno_msg_cybersecurity_4')}</li>
                                        <li className="mt-1 list-item">{t('sepro_techno_msg_cybersecurity_5')}</li>
                                        <li className="mt-1 list-item">{t('sepro_techno_msg_cybersecurity_6')}</li>
                                        <li className="mt-1 list-item">{t('sepro_techno_msg_cybersecurity_7')}</li>
                                        <li className="mt-1 list-item">{t('sepro_techno_msg_cybersecurity_8')}</li>
                                        <li className="mt-1 list-item">{t('sepro_techno_msg_cybersecurity_9')}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div
                            className="lg:col-start-7 flex-auto lg:row-start-1 lg:col-span-6 xl:col-span-6">
                            <div className="aspect-w-5 aspect-h-2 bg-gray-100 overflow-hidden">
                                <div aria-hidden="true">
                                    <StaticImage
                                        src={"../../../static/technologies/cybersecurite.jpg"}
                                        alt={t('sepro_cybersecurity')}
                                        className="object-cover object-center w-full h-full"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
